@import "./Colors.scss";
@import "./Props.scss";
@import "./media.scss";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

.font,
body {
  font-family: "Poppins";
}

.font-b {
  font-family: "Montserrat", sans-serif;
}
.tc {
  color: $themeColor;
}
@mixin bgImage($radius: 50%) {
  height: 100%;
  width: 100%;
  border-radius: $radius;
  background-color: #e2e2e2;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

@mixin dropDownAnim($duration: 0.3s) {
  animation-name: drop;
  animation-duration: $duration;
  transform-origin: top;
}

@keyframes drop {
  0% {
    transform: scaleY(0.5);
    opacity: 0;
  }
}

.wrapWidth {
  width: 1180px;
  margin: 0 auto;
  @include media("<=xlarge") {
    width: 1100px;
    margin: 0 auto;
  }
  @include media("<=large") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=desktop") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=tablet") {
    width: 540px;
    padding: 0px 3%;
  }
  @include media(">=phone", "<=480px") {
    padding: 0px 5%;
  }
}

.App {
  min-height: 100vh;
  background: #e5e5e5;
}

.app-pages {
  width: 100%;
}

.error-message {
  background: rgba(255, 66, 66, 0.1);
  color: #ff4242;
  font-weight: 400;
  font-size: 14px;
}

//Drop Down
.dropDown {
  width: 100%;
  .category {
    width: 100%;
    .cbox {
      background: transparent;
      border: 1px solid #eaeaea;
      padding: 0 16px;
      min-height: 42px;
      border-radius: 5px;
      box-sizing: border-box;
      min-width: 180px;
      width: 100%;
      justify-content: space-between;
      &:hover {
        // border: 1px solid #fff;
      }
      @include media(">=phone", "<=480px") {
        min-width: 150px;
      }
      .icon-arrow {
        svg {
          path {
            fill: $themeColor;
          }
        }
      }
      .slt {
        overflow: hidden;
        .ico {
          cursor: pointer;
          color: #000000d9;
          height: 22px;
          display: flex;
          align-items: center;
        }
        .lbl {
          color: #000;
          padding: 0 10px;
        }
        .unit-name {
          .unit-eng {
            color: #2e2e2e;
          }
        }
      }
      .d-img {
        margin: 2px 10px;
        .img {
          height: 25px;
          width: 25px;
          padding: 2.5px;
          border-radius: 100%;
          border: 1px solid $themeColor;
        }
      }
      .edit-icon {
        margin: 2px 10px;
        padding: 4px;
        border-radius: 100%;
        border: 1px solid $themeColor;
      }
      .dropDown-icon {
        cursor: pointer;
      }
    }
  }
  .block {
    top: 45px;
    left: 0;
    right: 0;
    border-radius: 4px;
    border: 1px solid $themeColor;
    background: #fff;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    width: 100%;
    .manue {
      width: 100%;
      // height: 150px;
      overflow-y: auto;
      padding: 0px 0px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      .slt {
        width: 100%;
        padding: 8px 16px;
        @include anim;
        &.active {
          background-color: $themeColor;
          color: #242424;
          .unit-name {
            .unit-eng {
              color: #242424;
            }
          }
        }
        .unit-name {
          .unit-eng {
            color: #242424;
          }
        }
        .ico {
          justify-content: flex-start;
          margin-right: 8px;
          width: 25px;
          height: 25px;
        }
        .lbl {
          flex: 1;
          margin-left: 5px;
          justify-content: flex-start;
        }
        &:hover {
          cursor: pointer;
          .ico {
            color: $themeColor;
          }
          .lbl {
            color: $themeColor;
          }
          .unit-name {
            .unit-eng {
              color: #fff;
            }
          }
          background: $themeColor;
        }
      }
    }
    &.show {
      display: flex;
      visibility: visible;
      opacity: 1;
      @include dropDownAnim;
    }
  }
}

// Login Page
.login-p {
  min-height: 730px;
  // height: 100vh;
  justify-content: center;
  .wrapper {
    width: 450px;
    padding-bottom: 60px;
    @include media(">=320px", "<=480px") {
      width: 100%;
      padding: 0px 24px;
      padding-bottom: 70px;
    }
    .logo {
      margin-bottom: 40px;
      .img {
        // height: 34px;
      }
    }
    .form-block {
      width: 100%;
      padding: 40px 40px;
      background: #ffffff;
      .title {
        color: #2e2e2e;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 55px;
        text-align: center;
      }
      .feild {
        width: 100%;
        // margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
        .lbl {
          color: #717579;
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 4px;
        }
        .iput {
          width: 100%;
          color: #0f0f0f;
          background: #fff;
          border: 1px solid #d7d7d7;
          padding: 10px 12px;
          border-radius: 5px;

          &:focus {
            border-color: $themeColor;
          }
        }
        .submit-btn {
          background: $themeColor;
          padding: 10px 20px;
          border-radius: 5px;
          margin-top: 5px;
          &:hover {
            opacity: 0.8;
          }
        }
        .btn {
          border-radius: 5px;
          font-size: 16px;
          background: $themeColor !important;
        }
      }
      .row {
        .item-left {
          .check-box {
          }
          .checkbox-lbl {
            color: #626679;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
          }
        }
        .item-right {
          .checkbox-lbl {
            color: #626679;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.register-p {
  min-height: 730px;
  // height: 100vh;
  justify-content: center;
  .wrapper {
    width: 600px;
    padding-bottom: 60px;
    @include media(">=320px", "<=480px") {
      width: 100%;
      padding: 0px 24px;
      padding-bottom: 70px;
    }
    .logo {
      margin-bottom: 40px;
      .img {
        // height: 34px;
      }
    }
    .form-block {
      width: 100%;
      padding: 40px 40px;
      background: #ffffff;
      .title {
        color: #161c2d;
        margin-bottom: 25px;
      }
      .row {
        grid-gap: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      .feild {
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
        .lbl {
          margin-bottom: 4px;
        }
        .iput {
          width: 100%;
          background: #fff;
          border: 1px solid #ddd;
          padding: 10px 12px;
          border-radius: 5px;
          &:focus {
            border-color: $themeColor;
          }
        }
        .submit-btn {
          background: $themeColor;
          padding: 10px 20px;
          border-radius: 5px;
          margin-top: 5px;
          &:hover {
            opacity: 0.8;
          }
        }
        .btn {
          border-radius: 5px;
          font-size: 16px;
          background: $themeColor !important;
        }
      }
    }
  }
}

// Header
.header-camp {
  z-index: 999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #e5e5e5;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 90px;
  padding: 12px 0px;
  position: sticky;
  .wrap {
    @media only screen and (max-width: 480px) and (min-width: 320px) {
    }
    .left {
    }
    .right {
      .btn {
        padding: 6px;
        display: flex !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
        border-radius: 6px;
        .icon {
          margin-left: 3px;

          svg {
            height: 20px;
            width: 20px;
          }
        }
      }
      .about-user {
        padding-left: 26px;
        border-left: 1px solid #4e7a7d;
        .img {
        }
        .drop-menu {
          .user-info {
            padding-left: 16px;
            padding-right: 8px;
            .name {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              color: #2e2e2e;
            }
            .desi {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              color: #2e2e2e;
            }
          }
        }
        .arrow-down-icon {
          width: 15px;
          height: 15px;
          margin-bottom: 15px;
        }
      }
      .manue-profile {
        background: #fff;
        width: 180px;
        position: absolute;
        top: 85px;
        right: 30px;
        padding: 10px 0;
        background: #ffffff;
        box-shadow: 0px 0px 52px -30px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        &.show {
          @include dropDownAnim;
        }
        .tag {
          padding: 6px 10px;
          color: #818181;
          font-weight: 400;
          font-size: 14px;
          line-height: 29px;
          cursor: pointer;
          border-bottom: 0.5px solid #eee;
          @include anim;
          svg {
            margin-right: 10px;
            width: 18px;
            height: 18px;
          }
          &:last-child {
            // opacity: 0.03;
            border-bottom: none;
          }
          &:hover {
            background: $themeColor;
            color: #ffffff;
          }
        }
      }
    }
  }
}

// Nave Bar
.navbar {
  background: $themeColor;
  width: 255px;
  height: 100%;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 1000;
  @include media(">=phone", "<=480px") {
  }
  .wrap {
    width: 100%;
    .logo {
      padding: 30px 2%;
      .logo-img {
        height: 40px;
      }
    }
    .nav-list {
      height: 600px;
      overflow: auto;
      padding: 20px 3px;
      margin: 8px 0;
      .list-item {
        padding: 0 20px;
        width: 100%;
        cursor: pointer;
        border-left: 4px solid transparent;
        margin: 10px 0;
        @include anim;
        .select {
          padding: 10px 0;
          margin-bottom: 5px;
          .tab-icon {
            margin-left: 5px;
            svg {
              width: 24px;
              height: 24px;
              path {
                fill: #d7d7d7;
              }
            }
          }
          .lbl {
            color: #d7d7d7;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 27px;
            padding-right: 26%;
            padding-left: 12px;
          }
          .arrow-icon {
          }
          &:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 6px;
            svg {
              path {
                fill: #ffffff;
              }
            }
            .lbl {
              color: #ffffff;
            }
          }
        }
        .sub-menu {
          padding: 10px 25px;
          .select {
            padding: 8px;
            justify-content: space-between;
            .tab-icon {
              // visibility: hidden;
            }
            .lbl {
              padding-left: 4px;
              padding-right: 8px;
              font-size: 14px;
            }
          }
        }
      }
    }
    .logout-btn {
      padding: 20px 10px;
      .btn-out {
        padding: 0.75em 1em;
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        @include anim;
        .ico {
          padding-right: 8px;
          svg {
            width: 24px;
            height: 24px;
            path {
              fill: #d7d7d7;
            }
          }
        }
        .out-lbl {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 1.2em;
          line-height: 27px;
          color: #d7d7d7;
        }
        &:hover {
          background: rgba(255, 255, 255, 0.1);
          .out-lbl {
            color: #ffffff;
          }
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
    }
  }
}

// Home Page
.home-page {
  padding: 20px 0;
  width: 100%;
  min-height: 730px;
  .wrap {
    height: 100%;
    .reports {
      grid-gap: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .item {
        height: 260px;
        border-radius: 6px;
        background: #fff;
        padding: 16px 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
        .blk {
          // border-radius: 6px;
          padding: 20px;
          width: 100%;
          height: 100%;
          border-left: 10px solid $themeColor;
          .hdr {
            .title {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 36px;
              color: #2e2e2e;
            }
          }
          .current-year {
            font-weight: 600;
            font-size: 16px;
            color: #2e2e2e;
            border: 1px solid #eaeaea;
            border-radius: 6px;
            width: 100px;
            height: 45px;
          }
          .numbs {
            .num {
              font-weight: 700;
              font-size: 36px;
              line-height: 54px;
              color: #2e2e2e;
            }
          }
          .row {
            margin-bottom: 10px;
            .lbl {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #2e2e2e;
              opacity: 0.6;
              margin-right: 30px;
            }
            .numb {
              font-weight: 700;
              font-size: 24px;
              color: #2e2e2e;
            }
          }
          .action {
            .btn {
              border-radius: 6px;
            }
          }
        }
      }
    }
  }
}

// Company General Info
.company-info {
  padding: 20px 0;
  width: 100%;
  min-height: 730px;
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 16px;
    .action {
      padding-right: 30px;
    }
    .wrap-block {
      grid-gap: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .field {
        .lbl {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #717579;
          margin-bottom: 6px;
        }
        .txt {
          padding: 8px;
          font-weight: 400;
          font-size: 16px;
          color: #0f0f0f;
          border: 1px solid #d7d7d7;
          border-radius: 6px;
        }
        .date-picker {
          padding: 8px;
          font-weight: 400;
          font-size: 16px;
          color: #0f0f0f;
          border: 1px solid #d7d7d7;
          border-radius: 6px;
          .rdt {
            width: 100% !important;
            cursor: pointer;
          }
          .form-control {
            cursor: pointer;
            width: 100% !important;
          }
        }
      }
    }
    .action-back {
      .btn-back {
        cursor: pointer;
        background: #4e7a7d;
        border: 1px solid #4e7a7d;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
        border-radius: 6px;
        &:first-child {
          background: none;
          color: $themeColor;
        }
      }
    }
  }
}

// department page
.department-page {
  padding: 20px 0;
  width: 100%;
  min-height: 500px;
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 16px;
    .table-blk {
      .tbl {
        width: 100%;
        .row {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 6px;
          &:first-child {
            .col {
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 27px;
                color: #000000;
                .btn {
                  color: #4e7a7d;
                  font-weight: 500;
                  font-size: 13px;
                  padding: 6px 16px;
                  background: transparent;
                  border: 1px solid #4e7a7d;
                  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                  border-radius: 6px;
                }
              }
            }
          }
          .col {
            flex: 1;
            &:first-child {
              flex: 0.7;
            }
            &:nth-child(2) {
              flex: 0.5;
            }
            &:last-child {
              flex: 0.4;
              align-items: center;
              justify-content: center;
            }
            .col-item {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #262626;
              .btn {
                color: #4e7a7d;
                font-weight: 500;
                font-size: 13px;
                padding: 6px 16px;
                background: transparent;
                border: 1px solid #4e7a7d;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                border-radius: 6px;
              }
              .icon {
                margin: 5px;
              }
            }
          }
        }
      }
    }
  }
}

// Department Delete
.depart-delete {
  height: 250px;
  width: 550px;
  background: #ffffff;
  box-shadow: 0px 1px 120px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 16px;
  .wrap {
    width: 100%;
    .hdr {
      .icon {
        @include anim;
        &:hover {
          svg {
            path {
              fill: red;
            }
          }
        }
      }
    }
    .blk {
      flex: 1;
      .desc {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        text-align: center;
        color: #262626;
      }
      .actions {
        // padding-top: 30px;
        .btn {
          border: 1px solid #4e7a7d;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
          border-radius: 6px;
          &:first-child {
            color: #4e7a7d;
            margin-right: 20px;
            background: transparent;
          }
        }
      }
    }
  }
}

//Departmen Detail
.department-detail {
  padding: 20px 0;
  width: 100%;
  min-height: 430px;
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 16px;
    .field {
      .lbl {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #717579;
        margin-bottom: 6px;
      }
      .txt {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #0f0f0f;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
      }
    }
    .row {
      grid-gap: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .action {
      padding-top: 30px;
      .btn {
        padding: 8px;
        width: 110px;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
        &:first-child {
          margin-right: 14px;
          color: #4e7a7d;
          background: transparent;
        }
      }
    }
  }
}

//Departmen Detail
.new-department {
  padding: 20px 0;
  width: 100%;
  min-height: 430px;
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 16px;
    .field {
      .lbl {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #717579;
        margin-bottom: 6px;
      }
      .txt {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #0f0f0f;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
      }
    }
    .row {
      grid-gap: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .radito-select {
      flex: 1;
      .lbl {
        color: #0f0f0f;
        font-weight: 400;
        font-size: 14px;
      }
      .radio-btn {
        margin-left: 30px;
        // margin-right: 0px;
      }
    }
    .select-box {
      width: 150px;
      margin-bottom: 6px;
      .box-tag {
        flex: 0.7;
      }
      .check-box {
        flex: 1;
      }
    }
    .action {
      padding-top: 30px;
      .btn {
        padding: 8px;
        width: 110px;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
      }
    }
  }
}

// Bank Account page
.bank-account-page {
  padding: 20px 0;
  width: 100%;
  min-height: 500px;
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 16px;
    .table-blk {
      .tbl {
        width: 100%;
        .row {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 6px;
          &:first-child {
            .col {
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 27px;
                color: #000000;
                .btn {
                  color: #4e7a7d;
                  font-weight: 500;
                  font-size: 13px;
                  padding: 6px 16px;
                  background: transparent;
                  border: 1px solid #4e7a7d;
                  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                  border-radius: 6px;
                }
              }
            }
          }
          .col {
            flex: 1;
            &:first-child {
              // flex: 0.7;
            }
            &:nth-child(2) {
              flex: 1.5;
            }
            &:last-child {
              flex: 0.4;
              align-items: center;
              justify-content: center;
            }
            .col-item {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #262626;
              .btn {
                color: #4e7a7d;
                font-weight: 500;
                font-size: 13px;
                padding: 6px 16px;
                background: transparent;
                border: 1px solid #4e7a7d;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                border-radius: 6px;
              }
              .icon {
                margin: 5px;
              }
            }
          }
        }
      }
    }
  }
}

// Bank Account Update
.bank-ac-update {
  padding: 20px 0;
  width: 100%;
  min-height: 430px;
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 16px;
    .field {
      .lbl {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #717579;
        margin-bottom: 6px;
      }
      .txt {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #0f0f0f;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
      }
    }
    .row {
      grid-gap: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .action {
      padding-top: 30px;
      .btn {
        padding: 8px;
        width: 110px;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
        &:first-child {
          margin-right: 14px;
          color: #4e7a7d;
          background: transparent;
        }
      }
    }
  }
}

// CPF Account page
.cpf-account-page {
  padding: 20px 0;
  width: 100%;
  min-height: 500px;
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 16px;
    .table-blk {
      .tbl {
        width: 100%;
        .row {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 6px;
          &:first-child {
            .col {
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 27px;
                color: #000000;
                .btn {
                  color: #4e7a7d;
                  font-weight: 500;
                  font-size: 13px;
                  padding: 6px 16px;
                  background: transparent;
                  border: 1px solid #4e7a7d;
                  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                  border-radius: 6px;
                }
              }
            }
          }
          .col {
            flex: 1;
            &:first-child {
              // flex: 0.7;
            }
            &:nth-child(2) {
              // flex: 1.5;
            }
            &:last-child {
              flex: 0.4;
              align-items: center;
              justify-content: center;
            }
            .col-item {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #262626;
              .btn {
                color: #4e7a7d;
                font-weight: 500;
                font-size: 13px;
                padding: 6px 16px;
                background: transparent;
                border: 1px solid #4e7a7d;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                border-radius: 6px;
              }
              .icon {
                margin: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.date-picker {
  padding: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #0f0f0f;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  .rdt {
    width: 100% !important;
    cursor: pointer;
  }
  .form-control {
    cursor: pointer;
    width: 100% !important;
  }
}

.users-page {
  padding: 20px 0;
  width: 100%;
  min-height: 430px;
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 24px 20px;
    .page-hdr {
      margin-bottom: 10px;
      .field {
        .lbl {
          margin-right: 8px;
        }
        .date-picker {
          width: 150px;
        }
      }
      .search-box {
        padding: 8px;
        width: 400px;
        background: #f9f9f9;
        border-radius: 6px;
        border: 2px solid transparent;
        @include anim;
        &:focus-within {
          border-color: $themeColor;
        }
        .txt {
          width: 100%;
          padding: 0px 8px;
        }
      }
      .btn-export {
        display: flex !important;
        border-radius: 6px;
        margin-left: 16px;
        padding: 8px 12px;
        svg {
          margin-left: 5px;
          width: 14px;
          height: 14px;
        }
      }
    }
    .table-blk {
      .tbl {
        width: 100%;
        .row {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 6px;
          &:first-child {
            .col {
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 27px;
                color: #000000;
                .btn {
                  color: #4e7a7d;
                  font-weight: 500;
                  font-size: 13px;
                  padding: 6px 16px;
                  background: transparent;
                  border: 1px solid #4e7a7d;
                  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                  border-radius: 6px;
                }
              }
            }
          }
          .col {
            flex: 1;

            &:last-child {
              flex: 0.4;
              align-items: center;
              justify-content: center;
            }
            .col-item {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #262626;
              flex-wrap: wrap;
              word-break: break-word;
              .btn {
                color: #4e7a7d;
                font-weight: 500;
                font-size: 13px;
                padding: 6px 16px;
                background: transparent;
                border: 1px solid #4e7a7d;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                border-radius: 6px;
              }
              .icon {
                margin: 5px;
                svg {
                  height: 20px;
                  width: 20px;
                }
              }
            }
          }
        }
      }
    }
    .comments-box {
      .lbl {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #717579;
        margin-bottom: 6px;
      }
      .txt {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #0f0f0f;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
      }
    }
    .info-words {
      padding: 20px 0;
      grid-gap: 8px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .info-item {
        .short-key {
          flex: 0.2;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #000000;
          margin-right: 18px;
        }
        .key-desc {
          flex: 1;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
    .actions {
      padding: 30px 0;
      .btn {
        padding: 8px;
        width: 110px;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
        &:first-child {
          background: none;
          color: $themeColor;
          margin-right: 12px;
        }
      }
    }
  }
}

.acitivity-page {
  padding: 20px 0;
  width: 100%;
  min-height: 430px;
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 24px 20px;
    .page-hdr {
      margin-bottom: 10px;
      .field {
        .lbl {
          margin-right: 8px;
        }
        .date-picker {
          width: 150px;
        }
      }
      .search-box {
        padding: 8px;
        width: 400px;
        background: #f9f9f9;
        border-radius: 6px;
        border: 2px solid transparent;
        @include anim;
        &:focus-within {
          border-color: $themeColor;
        }
        .txt {
          width: 100%;
          padding: 0px 8px;
        }
      }
      .btn-export {
        display: flex !important;
        border-radius: 6px;
        margin-left: 16px;
        padding: 8px 12px;
        svg {
          margin-left: 5px;
          width: 14px;
          height: 14px;
        }
      }
    }
    .table-blk {
      .tbl {
        width: 100%;
        .row {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 6px;
          &:first-child {
            .col {
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 27px;
                color: #000000;
                .btn {
                  color: #4e7a7d;
                  font-weight: 500;
                  font-size: 13px;
                  padding: 6px 16px;
                  background: transparent;
                  border: 1px solid #4e7a7d;
                  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                  border-radius: 6px;
                }
              }
            }
          }
          .col {
            flex: 1;

            &:last-child {
              flex: 2;
              align-items: center;
              justify-content: center;
            }
            .col-item {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #262626;
              flex-wrap: wrap;
              word-break: break-word;
              .btn {
                color: #4e7a7d;
                font-weight: 500;
                font-size: 13px;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                &.success {
                  color: #45bf43;
                }
                &.failed {
                  color: #ff4242;
                }
              }
              .icon {
                margin: 5px;
                svg {
                  height: 20px;
                  width: 20px;
                }
              }
            }
          }
        }
      }
    }
    .comments-box {
      .lbl {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #717579;
        margin-bottom: 6px;
      }
      .txt {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #0f0f0f;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
      }
    }
    .info-words {
      padding: 20px 0;
      grid-gap: 8px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .info-item {
        .short-key {
          flex: 0.2;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #000000;
          margin-right: 18px;
        }
        .key-desc {
          flex: 1;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
    .actions {
      padding: 30px 0;
      .btn {
        padding: 8px;
        width: 110px;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
        &:first-child {
          background: none;
          color: $themeColor;
          margin-right: 12px;
        }
      }
    }
  }
}

.claims-details-page {
  .table-blk {
    .tbl {
      width: 100%;
      .row {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px 6px;
        &:first-child {
          .col {
            .col-item {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 27px;
              color: #000000;
              .btn {
                color: #4e7a7d;
                font-weight: 500;
                font-size: 13px;
                padding: 6px 16px;
                background: transparent;
                border: 1px solid #4e7a7d;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                border-radius: 6px;
              }
            }
          }
        }
        .col {
          flex: 1;

          &:last-child {
            flex: 0.4;
            align-items: center;
            justify-content: center;
          }
          &:nth-child(3) {
            flex: 0.6;
          }
          &:nth-child(4) {
            flex: 2;
          }
          .col-item {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #262626;
            flex-wrap: wrap;
            word-break: break-word;
            .btn {
              color: #4e7a7d;
              font-weight: 500;
              font-size: 13px;
              padding: 6px 16px;
              background: transparent;
              border: 1px solid #4e7a7d;
              filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
              border-radius: 6px;
            }
            .icon {
              margin: 5px;
              svg {
                height: 20px;
                width: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.create-new-user-page {
  padding: 20px 0;
  width: 100%;
  min-height: 730px;
  .wrap {
    height: 100%;
    .page-hdr {
      .steps-blk {
        width: 100%;
        .line {
          width: 100%;
          z-index: 0;
          border-bottom: 1px dashed $themeColor;
        }
        .step-btn {
          min-width: 148px;
          padding: 8px;
          color: #ffffff;
          font-size: 12px;
          z-index: 1;
          background: #d4d4d4;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
          border-radius: 6px;
          &.active {
            background: $themeColor;
          }
        }
      }
    }
    .action {
      padding: 4px 0;
      .btn {
        padding: 8px;
        width: 110px;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
        &.hidden {
          display: none;
        }
        &:first-child {
          color: $themeColor;
          background: transparent;
        }
      }
    }
  }
}

.bd {
  border: 1px solid #d7d7d7;
}
.general-info-camp {
  padding: 20px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  .wrap-info {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 24px 20px;
    .page-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 36px;
      color: #0f0f0f;
    }
    .field {
      width: 100%;
      .lbl {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #717579;
        margin-bottom: 6px;
      }
      .txt {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #0f0f0f;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
      }
      .cross-icon {
        width: 34px;
        height: 100%;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff4242;
        background: rgba(255, 66, 66, 0.1);
        @include anim;
      }
    }
    .row {
      grid-gap: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .action {
      padding-top: 30px;
      .btn {
        padding: 8px;
        width: 110px;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
      }
    }
  }
}

.working-days-camp {
  padding: 20px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;

  .wrap-info {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 24px 20px;
    .page-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 36px;
      color: #0f0f0f;
    }

    .field {
      width: 100%;
      .lbl {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #717579;
        margin-bottom: 6px;
      }
      .txt {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #0f0f0f;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
      }
      .item {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    .row {
      grid-gap: 20px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    .table-blk {
      .row-txt {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #0f0f0f;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
      }
      .tbl {
        width: 100%;
        .tbl-row {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 6px;
          &:first-child {
            .tbl-col {
              flex: 1;
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 27px;
                color: #000000;
                .btn {
                  color: #4e7a7d;
                  font-weight: 500;
                  font-size: 13px;
                  padding: 6px 16px;
                  background: transparent;
                  border: 1px solid #4e7a7d;
                  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                  border-radius: 6px;
                }
              }
            }
          }
          .tbl-col {
            &:last-child {
              flex: 0.4;
              align-items: center;
              justify-content: center;
            }
            .col-item {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #262626;
              .btn {
                color: #4e7a7d;
                font-weight: 500;
                font-size: 13px;
                padding: 6px 16px;
                background: transparent;
                border: 1px solid #4e7a7d;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                border-radius: 6px;
              }
              .icon {
                margin: 5px;
              }
            }
          }
        }
      }
    }

    .action {
      padding-top: 30px;
      .btn {
        padding: 8px;
        width: 110px;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
      }
    }
  }
}

.payroll-page {
  padding: 20px 0;
  width: 100%;
  min-height: 430px;
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 24px 20px;
    .page-tag {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #0f0f0f;
    }
    .page-hdr {
      margin-bottom: 10px;
      .field {
        .lbl {
          margin-right: 8px;
        }
        .date-picker {
          width: 150px;
        }
      }
      .search-box {
        padding: 8px;
        width: 400px;
        background: #f9f9f9;
        border-radius: 6px;
        .txt {
          width: 100%;
          padding: 0px 8px;
        }
      }
      .btn-export {
        display: flex !important;
        border-radius: 6px;
        padding: 8px 12px;
        font-size: 12px;
        svg {
          margin-left: 5px;
          width: 14px;
          height: 14px;
          path {
            fill: #fff;
          }
        }
      }
    }
    .table-blk {
      .tbl {
        width: 100%;
        .row {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 6px;
          &:first-child {
            .col {
              .col-item {
                line-height: 27px;
                color: #000000;
                .btn {
                  color: #4e7a7d;
                  font-weight: 500;
                  font-size: 13px;
                  padding: 6px 16px;
                  background: transparent;
                  border: 1px solid #4e7a7d;
                  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                  border-radius: 6px;
                }
              }
            }
          }
          .col {
            flex: 1;
            // &:nth-child(3),
            // &:nth-child(4),
            // &:nth-child(5) {
            //   flex: 0.5;
            // }
            &:last-child {
              flex: 1.5;
              align-items: center;
              justify-content: center;
              flex-direction: row;
            }
            .col-item {
              line-height: 24px;
              color: #262626;
              flex-wrap: wrap;
              word-break: break-word;
              .btn {
                color: #4e7a7d;
                font-weight: 500;
                font-size: 13px;
                padding: 6px 16px;
                background: transparent;
                border: 1px solid #4e7a7d;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                border-radius: 6px;
              }
              .icon {
                margin: 5px;
                svg {
                  height: 20px;
                  width: 20px;
                }
              }
            }
          }
        }
      }
    }
    .comments-box {
      .lbl {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #717579;
        margin-bottom: 6px;
      }
      .txt {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #0f0f0f;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
      }
    }
    .info-words {
      padding: 20px 0;
      grid-gap: 8px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .info-item {
        .short-key {
          flex: 0.2;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #000000;
          margin-right: 18px;
        }
        .key-desc {
          flex: 1;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
    .actions {
      padding: 30px 0;
      .btn {
        padding: 8px;
        width: 110px;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
        &:first-child {
          background: none;
          color: $themeColor;
          margin-right: 12px;
        }
      }
    }
  }
}

// Payroll Detail
.payroll-detail {
  min-height: 350px;
  border: 1px solid #000000;
  padding: 12px;
  background: rgba(196, 196, 196, 0.4);
  .detail-hdr {
    .hdr-left {
      .form-control {
        background: transparent !important;
      }
      .date-picker {
        width: 160px;
      }
      .selct-date {
        width: 80%;
        padding: 14px 0;
        .field {
          .lbl {
            margin-right: 12px;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #717579;
          }
        }
      }
    }
    .hdr-right {
      width: 100%;
      height: 100%;
      .meta {
        width: 100%;
        grid-gap: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .meta-item {
          .page-tag {
          }
          .price {
            font-weight: 600;
            font-size: 28px;
            line-height: 54px;
            color: #0f0f0f;
          }
          .lbl-detail {
            cursor: pointer;
            color: #000000;
            line-height: 24px;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .main-block {
    margin: 20px 0;
    background: #ffffff;
    .tabs {
      flex: 1;
      .tab-box {
        flex: 1;
        padding: 8px;
        background: #d4d4d4;
        cursor: pointer;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #ffffff;
        &.active {
          background: $themeColor;
        }
      }
    }
    .blk-actions {
      padding: 30px 14px;
      .action-left {
        flex: 0.3;
        .btn {
          background: $themeColor;
          width: 100%;
          color: #ffffff;
          padding: 6px 12px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
          border-radius: 6px;
          display: flex !important;
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
      .action-center {
        flex: 1;
        .search-box {
          padding: 8px;
          width: 400px;
          background: #f9f9f9;
          border-radius: 6px;
          .txt {
            width: 100%;
            padding: 0px 8px;
          }
        }
      }
      .action-right {
        flex: 0.5;
        .btn {
          background: $themeColor;
          width: 100%;
          color: #ffffff;
          padding: 6px 12px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
          border-radius: 6px;
          display: flex !important;
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
    .filters-tabs {
      padding: 30px 12px;
      .tab-btn {
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        background: #d4d4d4;
        border: 1px solid #d4d4d4;
        padding: 8px 12px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
        border-radius: 6px;
        margin-left: 8px;
        cursor: pointer;
        &.active {
          background: $themeColor;
          border: 1px solid $themeColor;
        }
      }
    }
    .payment-summary-table-blk {
      .tbl {
        width: 100%;
        .row {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 6px;
          &:first-child {
            .col {
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #000000;
                padding: 0px 5px;
              }
            }
          }
          .col {
            flex: 1 1;
            &:first-child {
              flex: 0.2;
            }
            // &:nth-child(2) {
            //   flex: 0.8;
            // }
            // &:nth-child(3) {
            //   flex: 0.8;
            // }
            &:nth-child(8) {
              flex: 0.5;
            }
            &:last-child {
              // flex: none;
              flex: 1.5;
              justify-content: end;
            }
            .col-item {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 24px;
              color: #262626;
              flex-wrap: nowrap;
              word-break: break-word;
              padding: 0px 5px;
              .btn {
                color: #4e7a7d;
                font-weight: 500;
                font-size: 13px;
                padding: 6px 16px;
                background: transparent;
                border: 1px solid #4e7a7d;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                border-radius: 6px;
                word-break: keep-all;
              }
              .icon {
                margin: 5px;
                cursor: pointer;
                .btn {
                  padding: 4px !important;
                  font-size: 12px;
                  background: $themeColor;
                  color: #ffffff;
                  font-weight: 500;
                  // text-decoration: none !important;
                }
                svg {
                  height: 18px;
                  width: 18px;
                  path {
                    fill: $themeColor;
                  }
                  path.enabled {
                    fill: $themeColor;
                  }
                  path.disabled {
                    fill: #d4d4d4;
                    cursor: default;
                  }
                }
              }
            }
          }
        }
      }
    }
    .salary-summary-table-blk {
      .tbl {
        width: 100%;
        .row {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 6px;
          &:first-child {
            .col {
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #000000;
                padding: 0px 5px;
              }
            }
          }
          .col {
            flex: 1 1;
            &:first-child {
              flex: 0.2;
            }
            // &:nth-child(2) {
            //   flex: 0.8;
            // }
            // &:nth-child(3) {
            //   flex: 0.8;
            // }
            &:nth-child(10) {
              flex: 0.5;
            }
            &:last-child {
              // flex: none;
              flex: 2;
              justify-content: end;
            }
            .col-item {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 24px;
              color: #262626;
              flex-wrap: nowrap;
              word-break: break-word;
              padding: 0px 5px;
              .btn {
                color: #4e7a7d;
                font-weight: 500;
                font-size: 13px;
                padding: 6px 16px;
                background: transparent;
                border: 1px solid #4e7a7d;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                border-radius: 6px;
                word-break: keep-all;
              }
              .icon {
                margin: 5px;
                cursor: pointer;
                .btn {
                  padding: 4px !important;
                  font-size: 12px;
                  background: $themeColor;
                  color: #ffffff;
                  font-weight: 500;
                  // text-decoration: none !important;
                }
                svg {
                  height: 18px;
                  width: 18px;
                  path {
                    fill: $themeColor;
                  }
                  path.enabled {
                    fill: $themeColor;
                  }
                  path.disabled {
                    fill: #d4d4d4;
                    cursor: default;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .actions-pay {
    padding: 12px;
    .btn {
      padding: 8px 12px;
      color: #ffffff;
      border: 1px solid #d4d4d4;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      @include anim;
      &:hover {
        background: $themeColor;
      }
    }
  }
}

// Statutory Funds Detail
.statutory-detail {
  width: 480px;
  min-height: 150px;
  background: #ffffff;
  .detail-wrap {
    padding: 20px;
    .detail-hdr {
      .hdr-top {
        .page-tag {
          width: 100%;
          text-align: center;
          color: #262626;
          font-weight: 600;
          font-size: 18px;
        }
        .icon {
          @include anim;
          &:hover {
            svg {
              path {
                fill: red;
              }
            }
          }
        }
      }
      .hdr-btm {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    .detail-box {
      .total-emp {
        .row-head {
          color: #262626;
          font-weight: 500;
          font-size: 15px;
          padding-bottom: 4px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          .row-left {
          }
          .row-right {
            font-weight: 500;
            font-size: 16px;
          }
        }
        .row-list {
          display: none;
          &.show {
            display: flex;
          }
          .row {
            color: #262626;
            font-weight: 400;
            font-size: 13px;
            padding: 4px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .row-le {
              flex: 1;
              padding-left: 50px;
            }
            .row-re {
              flex: 0.9;
            }
          }
        }
      }
    }
  }
}

// New Payroll
.new-payroll-page {
  padding: 20px 0;
  width: 100%;
  min-height: 430px;
  .wrap {
    .page-tag {
      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      color: #0f0f0f;
    }
    .hdr-filters {
      background: #ffffff;
      padding: 20px;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 3%);
      border-radius: 8px;
      .row {
        // grid-gap: 50px;
        grid-column-gap: 60px;
        grid-row-gap: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .row-field {
          .input-field {
            grid-gap: 10px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            .date-picker {
              // width: 150px;
              .form-control {
                background: transparent;
              }
            }
            .txt {
              background: transparent;
              border: 1px solid #d7d7d7;
              border-radius: 6px;
              padding: 8px;
              font-weight: 400;
              font-size: 16px;
              color: #0f0f0f;
            }
          }
        }
      }
    }
    .user-list-block {
      background: #ffffff;
      padding: 20px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
      border-radius: 8px;
      min-height: 300px;
      margin: 40px 0;
      .block-hdr {
        .tag {
          font-weight: 600;
          font-size: 18px;
          color: #0f0f0f;
        }
        .search-box {
          padding: 8px;
          width: 400px;
          background: #f9f9f9;
          border-radius: 6px;
          border: 2px solid transparent;
          @include anim;
          &:focus-within {
            border: 2px solid $themeColor;
          }
          .txt {
            width: 100%;
            padding: 0px 8px;
          }
        }
      }
      .summary-table-blk {
        .tbl {
          width: 100%;
          .row {
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 10px 6px;
            &:first-child {
              .col {
                .col-item {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 27px;
                  color: #000000;
                }
              }
            }
            .col {
              flex: 1;
              &:first-child {
                flex: 0.6;
              }

              // &:last-child {
              //   flex: 1;
              //   align-items: center;
              //   justify-content: center;
              // }
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 24px;
                color: #262626;
                flex-wrap: wrap;
                word-break: break-word;
                .btn {
                  color: #4e7a7d;
                  font-weight: 500;
                  font-size: 13px;
                  padding: 6px 16px;
                  background: transparent;
                  border: 1px solid #4e7a7d;
                  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                  border-radius: 6px;
                }
                .icon {
                  margin: 5px;
                  cursor: pointer;
                  .btn {
                    padding: 4px !important;
                    font-size: 12px;
                    background: $themeColor;
                    color: #ffffff;
                    font-weight: 500;
                    // text-decoration: none !important;
                  }
                  svg {
                    height: 18px;
                    width: 18px;
                    path {
                      fill: $themeColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .actions-pay {
      padding: 12px;
      .btn {
        width: 100px;
        padding: 8px 12px;
        color: #ffffff;
        background: $themeColor;
        border: 1px solid $themeColor;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
        border-radius: 6px;
        @include anim;
        &:first-child {
          background: transparent;
          color: $themeColor;
        }
        &:hover {
          color: #ffffff;
          background: $themeColor;
        }
      }
    }
  }
}

//Edit Payroll Process
.edit-payroll-process {
  background: #ffffff;
  width: 550px;
  min-height: 362px;
  padding: 20px;
  .wrap {
    .heading-tag {
      .box-tag {
        font-weight: 600;
        font-size: 24px;
        line-height: 54px;
        color: #0f0f0f;
      }
      .icon {
        @include anim;
        svg {
          &:hover {
            path {
              fill: red;
            }
          }
        }
      }
    }
    .hdr-filters {
      background: #ffffff;
      padding: 20px 0;
      .row {
        grid-column-gap: 60px;
        grid-row-gap: 20px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        .row-field {
          .page-tag {
            font-weight: 500;
            font-size: 16px;
          }
          .input-field {
            grid-gap: 10px;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            .date-picker {
              width: 100%;
              .form-control {
                background: transparent;
              }
            }
            .txt {
              background: transparent;
              border: 1px solid #d7d7d7;
              border-radius: 6px;
              padding: 8px;
              font-weight: 400;
              font-size: 16px;
              color: #0f0f0f;
            }
          }
        }
      }
    }
    .react-datepicker-ignore-onclickoutside {
      width: 100% !important;
    }
    .react-datepicker__triangle {
      &::before {
        top: 0px !important;
        left: -15px !important;
      }
      &::after {
        left: -15px !important;
      }
    }
    .actions {
      margin-top: 40px;
      .btn {
        border: 1px solid $themeColor;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;

        &:first-child {
          margin-right: 24px;
          color: $themeColor;
          background: transparent;
        }
      }
    }
  }
}

.cpf-summary {
  .cpf-table-blk {
    .tbl {
      width: 100%;
      .row {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px 6px;
        &:first-child {
          .col {
            .col-item {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 27px;
              color: #000000;
            }
          }
        }
        .col {
          flex: 1;
          &:first-child {
            flex: 0.2;
          }
          &:nth-child(2) {
            flex: 0.8;
          }
          &:nth-child(3) {
            flex: 0.8;
          }
          &:nth-child(5) {
            flex: 0.8 !important;
          }
          &:last-child {
            flex: 2.2 !important;
            align-items: center;
            justify-content: center;
          }
          .col-item {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            color: #262626;
            flex-wrap: wrap;
            word-break: break-word;
            align-items: center !important;
            justify-content: center !important;
            .btn {
              color: #4e7a7d;
              font-weight: 500;
              font-size: 13px;
              padding: 6px 16px;
              background: transparent;
              border: 1px solid #4e7a7d;
              filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
              border-radius: 6px;
            }
            .icon {
              margin: 5px;
              cursor: pointer;
              .btn {
                padding: 4px !important;
                font-size: 12px;
                background: $themeColor;
                color: #ffffff;
                font-weight: 500;
                // text-decoration: none !important;
              }
              svg {
                height: 18px;
                width: 18px;
                path {
                  fill: $themeColor;
                }
                path.enabled {
                  fill: $themeColor;
                }
                path.disabled {
                  fill: #d4d4d4;
                  cursor: default;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Payment Information
.payment-information {
  min-height: 200px;
  max-height: 400px;
  width: 640px;
  background-color: #fff;
  .payment-info-wrap {
    padding: 18px;
    .payment-info-hdr {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      .page-tag {
        font-weight: 600;
        font-size: 22px;
        color: #0f0f0f;
      }
      .cross-icon {
        @include anim;
        &:hover {
          svg {
            path {
              fill: red;
            }
          }
        }
      }
    }
    .meta {
      padding-top: 24px;
      .row {
        grid-gap: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .field {
          .lbl {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #717579;
            margin-bottom: 6px;
          }
          .txt {
            padding: 8px;
            font-weight: 400;
            font-size: 16px;
            color: #0f0f0f;
            border: 1px solid #d7d7d7;
            border-radius: 6px;
            @include anim;
            &:focus {
              border-color: $themeColor;
            }
          }

          .option-item .lbl {
            margin-bottom: 0px;
          }

          .option-item .radio-btn {
            margin: auto;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

// Add Payroll Users
.payroll-users {
  min-height: 400px;
  width: 940px;
  background-color: #fff;
  .payroll-users-wrap {
    padding: 18px;
    .payroll-hdr {
      .left {
        flex: 1;
        .select {
          width: 100%;
        }
      }
      .right {
        flex: 1;
        .search-box {
          padding: 8px;
          // width: 400px;
          background: #f9f9f9;
          border-radius: 6px;
          .txt {
            width: 100%;
            padding: 0px 8px;
          }
        }
      }
    }
    .container {
      padding: 20px 0;
      height: 280px;
      overflow: auto;
      .table-blk {
        .tbl {
          width: 100%;
          .row {
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 10px 6px;
            &:first-child {
              .col {
                .col-item {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: #000000;
                  .btn {
                    color: #4e7a7d;
                    font-weight: 500;
                    font-size: 13px;
                    padding: 6px 16px;
                    background: transparent;
                    border: 1px solid #4e7a7d;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                    border-radius: 6px;
                  }
                }
              }
            }
            .col {
              flex: 1;
              &:first-child {
                flex: 0.2;
              }
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #262626;
                flex-wrap: wrap;
                word-break: break-word;
              }
            }
          }
        }
      }
    }
    .action {
      padding-top: 30px;
      .btn {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
        border-radius: 6px;
        &:last-child {
          margin-left: 16px;
          color: $themeColor;
          background: transparent;
        }
      }
    }
  }
}

// payroll Process
.payroll-process {
  height: 100vh;
  min-height: 400px;
  width: 940px;
  background-color: #fff;
  .wrap {
    padding: 18px;
    height: 100%;
    .tag {
      font-weight: 600;
      font-size: 16px;
      color: #0f0f0f;
    }
    .page-hdr {
      .heading {
        .date {
          font-weight: 500;
          font-size: 14px;
          color: #717579;
        }
      }
      .cross-icon {
        @include anim;
        &:hover {
          svg {
            path {
              fill: red;
            }
          }
        }
      }
    }
    .meta {
      height: 100%;
      // height: 300px;
      overflow: auto;
      padding-right: 6px;
      padding-top: 24px;
      .grid-row {
        grid-gap: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .field {
          .lbl {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #717579;
            margin-bottom: 6px;
          }
          .txt {
            padding: 8px;
            font-weight: 400;
            font-size: 16px;
            color: #0f0f0f;
            border: 1px solid #d7d7d7;
            border-radius: 6px;
            @include anim;
            &:focus {
              border-color: $themeColor;
            }
          }
        }
      }
      .over-time-sec {
        padding-bottom: 16px;
        border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
      }
      .table-blk {
        .tbl {
          width: 100%;
          .row {
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 10px 6px;
            &:first-child {
              .col {
                .col-item {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: #000000;
                  .btn {
                    color: #4e7a7d;
                    font-weight: 500;
                    font-size: 13px;
                    padding: 6px 16px;
                    background: transparent;
                    border: 1px solid #4e7a7d;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                    border-radius: 6px;
                  }
                }
              }
            }
            .col {
              flex: 1;
              &:last-child {
                flex: 0.2;
              }
              &:nth-child(3) {
                flex: 0.8;
              }
              .date-picker {
                width: 76%;
              }
              .txt {
                padding: 8px;
                font-weight: 400;
                font-size: 16px;
                color: #0f0f0f;
                border: 1px solid #d7d7d7;
                border-radius: 6px;
                @include anim;
                &:focus {
                  border-color: $themeColor;
                }
              }
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #262626;
                flex-wrap: wrap;
                word-break: break-word;
              }
            }
          }
        }
      }
      .total-amount {
        padding-top: 20px;
        .field {
          .lbl {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 6px;
          }
          .txt {
            width: 140px;
            padding: 8px;
            font-weight: 600;
            font-size: 16px;
            color: #0f0f0f;
            border: 1px solid #d7d7d7;
            border-radius: 6px;
            @include anim;
            &:focus {
              border-color: $themeColor;
            }
          }
        }
        .btn {
          width: 160px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
          border-radius: 6px;
        }
      }
    }
  }
}

.test-pdf {
  // display: none;
}
.bd-t {
  border-top: 1.6px solid #8f8f8f;
}

.pay-slip-model {
  overflow: hidden;
  height: 100vh;
  min-width: 780px;
  background-color: #ffffff;
  .action {
    padding: 10px 20px;
    justify-content: end;
  }
}
// Payslip Page
.payslip-page {
  height: 1000px;
  // width: 100vw;
  overflow: auto;
  background-color: #ffffff;
  .wrap {
    padding: 30px 5%;
    width: 100%;
    .page-container {
      flex: 1;
      justify-content: space-between;
      min-height: 1000px;
      .meta {
        .page-tag {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 44px;
          color: #2e2e2e;
          padding-bottom: 8px;
          border-bottom: 1.6px solid #8f8f8f;
        }
        .company-name {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 44px;
          color: #2e2e2e;
          padding: 10px 0;
        }
        .company-infos {
          padding: 12px 0;
          .comp-left {
            flex: 1;
            margin-right: 30px;
            .comp-info-lbl {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 22px;
              color: #0f0f0f;
            }
          }
          .comp-right {
            flex: 0.7;
            margin-left: 30px;
            .item {
              margin-bottom: 4px;
              .item-tag {
                flex: 1;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #2e2e2e;
              }
              .colon {
                margin: 0 20px;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #2e2e2e;
              }
              .item-lbl {
                flex: 1;
                font-size: 18px;
                line-height: 22px;
                color: #2e2e2e;
              }
            }
          }
        }
        .employee-infos {
          border-top: 1.6px solid #8f8f8f;
          border-bottom: 1.6px solid #8f8f8f;
          padding: 12px 0;
          .employee-name {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 26px;
            line-height: 44px;
            color: #2e2e2e;
            padding: 10px 0;
          }
          .employee-left {
            flex: 1;
            margin-right: 30px;
            .employee-info-lbl {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 22px;
              color: #0f0f0f;
            }
          }
          .employee-right {
            flex: 0.7;
            margin-left: 30px;
            .item-lbl {
              flex: 1;
              font-size: 18px;
              line-height: 22px;
              color: #2e2e2e;
              margin-bottom: 4px;
            }
          }
        }
        .salary-info {
          padding: 12px 0;
          .salary-info-left {
            flex: 1;
            margin-right: 30px;
            .salary-item {
              justify-content: space-between;
              margin-bottom: 6px;
              .salary-lbl {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #2e2e2e;
              }
              .salary-val {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #2e2e2e;
              }
            }
            .component-info {
              padding: 0 20px;
              .comp-item {
                margin-bottom: 6px;
                .comp-lbl {
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 17px;
                  color: #2e2e2e;
                }
                .comp-val {
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 17px;
                  color: #2e2e2e;
                }
              }
            }
            .gross-earning-item {
              justify-content: space-between;
              padding: 12px 0;
              border-top: 1.6px solid #8f8f8f;
              border-bottom: 1.6px solid #8f8f8f;
              .salary-lbl {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #2e2e2e;
              }
              .salary-val {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #2e2e2e;
              }
            }
            .net-salary {
              padding: 12px 0;
              border-top: 1.6px solid #8f8f8f;
              border-bottom: 1.6px solid #8f8f8f;
              .net-salary-lbl {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: #2e2e2e;
              }
            }
          }
          .salary-info-right {
            flex: 0.7;
            margin-left: 30px;
            .salary-item {
              justify-content: space-between;
              margin-bottom: 6px;
              .salary-lbl {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #2e2e2e;
              }
              .salary-val {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #2e2e2e;
              }
            }
            .component-info {
              padding: 0 10px;
              .comp-item {
                margin-bottom: 6px;
                .comp-lbl {
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 17px;
                  color: #2e2e2e;
                }
                .comp-val {
                  font-family: "Montserrat";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 17px;
                  color: #2e2e2e;
                }
              }
            }
          }
        }
      }
      .bank-info {
        .paid-by-bank {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #2e2e2e;
          padding: 20px 0;
        }
      }
    }
    .page-footer {
      border-top: 1.6px solid #8f8f8f;
      padding: 12px 0;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #2e2e2e;
    }
  }
}

input:disabled,
.disabled-input {
  background-color: #f2f2f2 !important;
}

.icon {
  margin: 5px;
  .btn {
    padding: 4px !important;
    font-size: 12px;
    background: $themeColor;
    color: #ffffff;
    font-weight: 500;
    // text-decoration: none !important;
  }
  svg {
    height: 18px;
    width: 18px;
    path.enabled {
      fill: $themeColor;
    }
    path.disabled {
      fill: #d4d4d4;
      cursor: default;
    }
  }
}

.cs-pointer {
  cursor: pointer;
}

.admin-sides .claim-list .col-item {
  .btn {
    width: 130px !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    padding: 6px 16px !important;
    background: transparent;
    border-radius: 6px !important;
    border: 0px !important;
    &.pending {
      color: #f3c13e !important;
      background: rgba(243, 193, 62, 0.1) !important;
    }
    &.approved {
      color: #45bf43 !important;
      background: rgba(69, 191, 67, 0.1) !important;
    }
    &.rejected {
      color: #ff4242 !important;
      background: rgba(255, 66, 66, 0.1) !important;
    }
    &.claimed {
      color: #4284ff;
      background: rgba(66, 132, 255, 0.1) !important;
    }
  }
}

.sidebar-item .sidebar-content a:first-child {
  margin-top: 15px;
}

.status-tag {
  padding: 6px 12px;
  border-radius: 18px;
  font-weight: 600;
  text-shadow: 1.5px 1px #323232;
  font-size: x-small;
  // border-bottom: 1px solid #323232;
  // border-right: 1.5px double #323232;
  &.secondary{
    text-shadow: 1px 1px 12px #898989;
    border: 1.5px solid #898989;
    color: #898989;
  }
  &.success{
    text-shadow: 1px 1px 12px #00914e;
    border: 1.5px solid #00914e;
    color: #00914e;
  }
  &.primary{
    text-shadow: 1px 1px 12px #2095d9;
    border: 1.5px solid #2095d9;
    color: #2095d9;
  }
  &.danger{
    text-shadow: 1px 1px 12px #b93535;
    border: 1.5px solid #b93535;
    color: #b93535;
  }
}


.enabled {
  fill: $themeColor;
  cursor: pointer;
}
.disabled {
  fill: #d4d4d4;
  cursor: default;
}
.rdt_TableCol {
  > * {
    > *{
      white-space: normal !important;
      word-break: break-word !important;
    }
  }
}
.rdt_TableCell {
  > * {
    white-space: normal !important;
    word-break: break-word !important;
  }
}
.rdt_TableHeadRow {
  text-align: center;
}