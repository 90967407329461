@import "./Colors.scss";
@import "./Props.scss";
@import "./media.scss";

@mixin bgImage($radius: 50%) {
  height: 100%;
  width: 100%;
  border-radius: $radius;
  background-color: #e2e2e2;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
@mixin dropDownAnim($duration: 0.3s) {
  animation-name: drop;
  animation-duration: $duration;
  transform-origin: top;
}
@keyframes drop {
  0% {
    transform: scaleY(0.5);
    opacity: 0;
  }
}

//User Side Home Page
.user-home-page {
  padding: 20px 0;
  width: 100%;
  min-height: 730px;

  .slider-block {
    width: 510px;

    @include media("<=xlarge") {
      width: 480px;
      margin: 0 auto;
    }
    @include media("<=large") {
      width: 310px;
      padding: 0px 2%;
    }
    @include media(">=phone", "<=480px") {
      padding: 0px 5%;
    }
    // Slick Slider
    .slick-slider {
      width: 100%;
      min-height: 90px;
    }
    .slick-next:before,
    .slick-prev:before {
      font-size: 14px !important;
      font-weight: bold !important;
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev:before {
      content: "\e901";
      color: white;
    }
    .slick-prev {
      left: 200px !important;
      z-index: 99;
      color: #000 !important;
      @include media(">=phone", "<=480px") {
        visibility: hidden;
      }
    }
    .slick-prev,
    .slick-next {
      background: $themeColor;
      color: #000 !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 12px;
      top: 86% !important;
    }

    .slick-next {
      right: 200px !important;
      color: #000 !important;
      z-index: 99;
      &:before {
        color: #fff;
        content: "\e900" !important;
      }
      @include media("<=phoneH") {
        width: 0px;
        height: 0px;
        &:before {
          font-size: 10px;
        }
      }
      @include media(">=phone", "<=480px") {
        visibility: hidden;
      }
    }
  }
  .wrap {
    height: 100%;
    .reports {
      grid-gap: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 30px;
      .item {
        height: 200px;
        border-radius: 6px;
        background: #fff;
        padding: 16px 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
        .blk {
          // border-radius: 6px;
          padding: 20px;
          width: 100%;
          height: 100%;
          border-left: 10px solid $themeColor;
          .hdr {
            .title {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 36px;
              color: #2e2e2e;
            }
          }
          .report-meta {
            grid-gap: 20px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
          }
          .row {
            margin-bottom: 10px;
            .lbl {
              font-weight: 500;
              font-size: 13px;
              line-height: 24px;
              color: #2e2e2e;
              opacity: 0.6;
              margin-right: 30px;
            }
            .numb {
              font-weight: 700;
              font-size: 26px;
              color: #2e2e2e;
            }
          }
          .action {
            .btn {
              border-radius: 6px;
            }
          }
        }
      }
    }
    .history-modification-list {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 3%);
      border-radius: 8px;
      height: 100%;
      padding: 24px 20px;
      .page-hdr {
        margin-bottom: 10px;
        .field {
          .lbl {
            font-size: 14px;
            margin-right: 8px;
          }
          .date-picker {
            width: 150px;
          }
        }
        .btn-export {
          display: flex !important;
          border-radius: 6px;
          margin-left: 16px;
          padding: 8px 12px;
          svg {
            margin-left: 5px;
            width: 14px;
            height: 14px;
          }
        }
      }
      .table-blk {
        .tbl {
          width: 100%;
          .row {
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 10px 6px;
            &:first-child {
              .col {
                .col-item {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 27px;
                  color: #000000;
                  .btn {
                    color: #4e7a7d;
                    font-weight: 500;
                    font-size: 13px;
                    padding: 6px 16px;
                    background: transparent;
                    border: 1px solid #4e7a7d;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                    border-radius: 6px;
                  }
                }
              }
            }
            .col {
              flex: 1;
              &:last-child {
                flex: 0.2;
                align-items: center;
                justify-content: center;
              }
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 24px;
                color: #262626;
                flex-wrap: wrap;
                word-break: break-word;
                .btn {
                  color: #4e7a7d;
                  font-weight: 500;
                  font-size: 13px;
                  padding: 6px 16px;
                  background: transparent;
                  border: 1px solid #4e7a7d;
                  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                  border-radius: 6px;
                }
                .icon {
                  margin: 5px;
                  svg {
                    height: 20px;
                    width: 20px;
                    path {
                      fill: $themeColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//User Side Payroll History
.user-payroll-history {
  padding: 20px 0;
  width: 100%;
  min-height: 730px;
  .wrap {
    height: 100%;
    .history-modification-list {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 3%);
      border-radius: 8px;
      height: 100%;
      padding: 24px 20px;
      .page-hdr {
        margin-bottom: 10px;
        .field {
          .lbl {
            font-size: 14px;
            margin-right: 8px;
          }
          .date-picker {
            width: 150px;
          }
        }
        .btn-export {
          display: flex !important;
          border-radius: 6px;
          margin-left: 16px;
          padding: 8px 12px;
          svg {
            margin-left: 5px;
            width: 14px;
            height: 14px;
          }
        }
      }
      .table-blk {
        .tbl {
          width: 100%;
          .row {
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 10px 6px;
            &:first-child {
              .col {
                .col-item {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 27px;
                  color: #000000;
                  .btn {
                    color: #4e7a7d;
                    font-weight: 500;
                    font-size: 13px;
                    padding: 6px 16px;
                    background: transparent;
                    border: 1px solid #4e7a7d;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                    border-radius: 6px;
                  }
                }
              }
            }
            .col {
              flex: 1;
              &:last-child {
                flex: 0.2;
                align-items: center;
                justify-content: center;
              }
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 24px;
                color: #262626;
                flex-wrap: wrap;
                word-break: break-word;
                .btn {
                  color: #4e7a7d;
                  font-weight: 500;
                  font-size: 13px;
                  padding: 6px 16px;
                  background: transparent;
                  border: 1px solid #4e7a7d;
                  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                  border-radius: 6px;
                }
                .icon {
                  margin: 5px;
                  svg {
                    height: 20px;
                    width: 20px;
                    path {
                      fill: $themeColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.users-claims-list {
  padding: 20px 0;
  width: 100%;
  min-height: 430px;
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 24px 20px;
    .page-hdr {
      margin-bottom: 10px;
    }
    .table-blk {
      .tbl {
        width: 100%;
        .row {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 6px;
          &:first-child {
            .col {
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 27px;
                color: #000000;
                .btn {
                  color: #4e7a7d;
                  font-weight: 500;
                  font-size: 13px;
                  padding: 6px 16px;
                  background: transparent;
                  border: 1px solid #4e7a7d;
                  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                  border-radius: 6px;
                }
              }
            }
          }
          .col {
            flex: 1;
            .col-item {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #262626;
              flex-wrap: wrap;
              word-break: break-word;
              .btn {
                width: 130px;
                color: #4e7a7d;
                font-weight: 500;
                font-size: 13px;
                padding: 6px 16px;
                background: transparent;
                // border: 1px solid #4e7a7d;
                // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                border-radius: 6px;
                &.pending {
                  color: #f3c13e;
                  background: rgba(243, 193, 62, 0.1);
                }
                &.approved {
                  color: #45bf43;
                  background: rgba(69, 191, 67, 0.1);
                }
                &.rejected {
                  color: #ff4242;
                  background: rgba(255, 66, 66, 0.1);
                }
                &.claimed {
                  color: #4284ff;
                  background: rgba(66, 132, 255, 0.1);
                }
              }
            }
            &:last-child {
              flex: 0.3;
            }
          }
        }
      }
    }
    .comments-box {
      .lbl {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #717579;
        margin-bottom: 6px;
      }
      .txt {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #0f0f0f;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
      }
    }
    .info-words {
      padding: 20px 0;
      grid-gap: 8px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .info-item {
        .short-key {
          flex: 0.2;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #000000;
          margin-right: 18px;
        }
        .key-desc {
          flex: 1;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
    .actions {
      padding: 30px 0;
      .btn {
        padding: 8px;
        width: 110px;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
        &:first-child {
          background: none;
          color: $themeColor;
          margin-right: 12px;
        }
      }
    }
  }
}

.users-claims-form {
  padding: 20px 0;
  width: 100%;
  min-height: 430px;
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 24px 20px;
    .page-hdr {
      margin-bottom: 10px;
      .field {
        .lbl {
          font-size: 14px;
          margin-right: 8px;
        }
        .date-picker {
          width: 200px;
        }
      }
    }
    .table-blk {
      .tbl {
        width: 100%;
        .row {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 6px;
          &:first-child {
            .col {
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 27px;
                color: #000000;
                .btn {
                  color: #4e7a7d;
                  font-weight: 500;
                  font-size: 13px;
                  padding: 6px 16px;
                  background: transparent;
                  border: 1px solid #4e7a7d;
                  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                  border-radius: 6px;
                }
              }
            }
          }
          .col {
            flex: 1;
            &:nth-child(3) {
              flex: 0.8;
              align-items: center;
              justify-content: center;
            }
            &:nth-child(4) {
              flex: 1.5;
            }
            &:last-child {
              flex: 0.4;
              align-items: center;
              justify-content: center;
            }
            .col-item {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #262626;
              flex-wrap: wrap;
              word-break: break-word;
              .btn {
                color: #4e7a7d;
                font-weight: 500;
                font-size: 13px;
                padding: 6px 16px;
                background: transparent;
                border: 1px solid #4e7a7d;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                border-radius: 6px;
              }
              .icon {
                margin: 5px;
                svg {
                  height: 20px;
                  width: 20px;
                }
              }
            }
          }
        }
      }
    }
    .actions {
      .btn {
        padding: 8px;
        width: 110px;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
      }
    }
  }
}

.add-new-claim {
  padding: 20px 0;
  width: 100%;
  min-height: 430px;
  .wrap {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 100%;
    padding: 16px;
    .field {
      .lbl {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #717579;
        margin-bottom: 6px;
      }
      .txt {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #0f0f0f;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
      }
    }
    .row {
      grid-gap: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .radito-select {
      flex: 1;
      .lbl {
        color: #0f0f0f;
        font-weight: 400;
        font-size: 14px;
      }
      .radio-btn {
        margin-left: 30px;
        // margin-right: 0px;
      }
    }
    .select-box {
      width: 150px;
      margin-bottom: 6px;
      .box-tag {
        flex: 0.7;
      }
      .check-box {
        flex: 1;
      }
    }
    .action {
      padding-top: 30px;
      .btn {
        padding: 8px;
        width: 110px;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
        &:first-child {
          background: transparent;
          color: $themeColor;
        }
      }
    }
  }
}

// Claims Approval List
.approval-claims-page {
  padding: 20px 0;
  width: 100%;
  min-height: 430px;
  .wrap {
    height: 100%;
    .approval-list {
      border-radius: 8px;
      // height: 100%;
      padding: 24px 20px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
      .table-expend {
        padding: 6px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
      .table-minimize {
        margin-top: 14px;
        &.tblShow {
          @include dropDownAnim;
          display: none;
        }
        .page-hdr {
          margin-bottom: 10px;
          .field {
            .lbl {
              margin-right: 8px;
            }
            .date-picker {
              width: 150px;
            }
          }
          .search-box {
            padding: 8px;
            width: 400px;
            background: #f9f9f9;
            border-radius: 6px;
            border: 2px solid transparent;
            @include anim;
            &:focus-within {
              border-color: $themeColor;
            }
            .txt {
              width: 100%;
              padding: 0px 8px;
            }
          }
          .btn-export {
            display: flex !important;
            border-radius: 6px;
            margin-left: 16px;
            padding: 8px 12px;
            svg {
              margin-left: 5px;
              width: 14px;
              height: 14px;
            }
          }
        }
        .table-blk {
          .tbl {
            width: 100%;
            .row {
              width: 100%;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              padding: 10px 6px;
              &:first-child {
                .col {
                  .col-item {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 27px;
                    color: #000000;
                    .btn {
                      color: #4e7a7d;
                      font-weight: 500;
                      font-size: 13px;
                      padding: 6px 16px;
                      background: transparent;
                      border: 1px solid #4e7a7d;
                      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                      border-radius: 6px;
                    }
                  }
                }
              }
              .col {
                flex: 1;

                &:last-child {
                  flex: 0.4;
                  align-items: center;
                  justify-content: center;
                }
                .col-item {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                  color: #262626;
                  flex-wrap: wrap;
                  word-break: break-word;
                  .btn {
                    color: #4e7a7d;
                    font-weight: 500;
                    font-size: 13px;
                    padding: 6px 16px;
                    background: transparent;
                    border: 1px solid #4e7a7d;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                    border-radius: 6px;
                  }
                  .img {
                    height: 40px;
                    width: 40px;
                    margin-right: 10px;
                    object-fit: contain;
                    border-radius: 50%;
                  }
                  .icon {
                    margin: 5px;
                    svg {
                      height: 20px;
                      width: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .approval-history {
      border-radius: 8px;
      // height: 100%;
      padding: 24px 20px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
      .page-hdr {
        margin-bottom: 10px;
        .field {
          .lbl {
            margin-right: 8px;
          }
          .date-picker {
            width: 150px;
          }
        }
        .search-box {
          padding: 8px;
          width: 400px;
          background: #f9f9f9;
          border-radius: 6px;
          border: 2px solid transparent;
          @include anim;
          &:focus-within {
            border-color: $themeColor;
          }
          .txt {
            width: 100%;
            padding: 0px 8px;
          }
        }
        .btn-export {
          display: flex !important;
          border-radius: 6px;
          margin-left: 16px;
          padding: 8px 12px;
          svg {
            margin-left: 5px;
            width: 14px;
            height: 14px;
          }
        }
      }
      .table-blk {
        .tbl {
          width: 100%;
          .row {
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 10px 6px;
            &:first-child {
              .col {
                .col-item {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 27px;
                  color: #000000;
                  .btn {
                    color: #4e7a7d;
                    font-weight: 500;
                    font-size: 13px;
                    padding: 6px 16px;
                    background: transparent;
                    border: 1px solid #4e7a7d;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                    border-radius: 6px;
                  }
                }
              }
            }
            .col {
              flex: 1;

              &:last-child {
                flex: 0.4;
                align-items: center;
                justify-content: center;
              }
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #262626;
                flex-wrap: wrap;
                word-break: break-word;
                .btn {
                  width: 100px;
                  font-weight: 500;
                  font-size: 12px;
                  padding: 4px 8px;
                  background: transparent;
                  border-radius: 6px;
                  &.pending {
                    color: #f3c13e;
                    background: rgba(243, 193, 62, 0.1);
                  }
                  &.approved {
                    color: #45bf43;
                    background: rgba(69, 191, 67, 0.1);
                  }
                  &.rejected {
                    color: #ff4242;
                    background: rgba(255, 66, 66, 0.1);
                  }
                  &.claimed {
                    color: #4284ff;
                    background: rgba(66, 132, 255, 0.1);
                  }
                }
                .img {
                  height: 40px;
                  width: 40px;
                  margin-right: 10px;
                  object-fit: contain;
                  border-radius: 50%;
                }

                .icon {
                  margin: 5px;
                  svg {
                    height: 20px;
                    width: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .comments-box {
      .lbl {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #717579;
        margin-bottom: 6px;
      }
      .txt {
        padding: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #0f0f0f;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
      }
    }
    .info-words {
      padding: 20px 0;
      grid-gap: 8px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .info-item {
        .short-key {
          flex: 0.2;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #000000;
          margin-right: 18px;
        }
        .key-desc {
          flex: 1;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
    .actions {
      padding: 30px 0;
      .btn {
        padding: 8px;
        width: 110px;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
        &:first-child {
          background: none;
          color: $themeColor;
          margin-right: 12px;
        }
      }
    }
  }
}

// Claims Approval List
.claims-details-page {
  padding: 20px 0;
  width: 100%;
  min-height: 430px;
  .wrap {
    height: 100%;
    .claims-details-table {
      border-radius: 8px;
      padding: 24px 20px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
      .page-hdr {
        margin-bottom: 10px;
        .field {
          .lbl {
            margin-right: 8px;
          }
          .date-picker {
            width: 150px;
          }
        }
        .search-box {
          padding: 8px;
          width: 400px;
          background: #f9f9f9;
          border-radius: 6px;
          border: 2px solid transparent;
          @include anim;
          &:focus-within {
            border-color: $themeColor;
          }
          .txt {
            width: 100%;
            padding: 0px 8px;
          }
        }
        .btn-export {
          display: flex !important;
          border-radius: 6px;
          margin-left: 16px;
          padding: 8px 12px;
          svg {
            margin-left: 5px;
            width: 14px;
            height: 14px;
          }
        }
      }
      .table-blk {
        .tbl {
          width: 100%;
          .row {
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 10px 6px;
            .check-box {
              height: 20px;
              width: 20px;
            }
            &:first-child {
              .col {
                .col-item {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 27px;
                  color: #000000;
                  .btn {
                    color: #4e7a7d;
                    font-weight: 500;
                    font-size: 13px;
                    padding: 6px 16px;
                    background: transparent;
                    border: 1px solid #4e7a7d;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
                    border-radius: 6px;
                  }
                }
              }
            }
            .col {
              flex: 1;
              // &:first-child {
              //   flex: 0.5;
              //   align-items: center;
              //   justify-content: center;
              // }
              &:last-child {
                flex: 2;
              }
              &:nth-child(3) {
                flex: 0.6;
                align-items: center;
                justify-content: center;
              }
              .col-item {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #262626;
                flex-wrap: wrap;
                word-break: break-word;
                .btn {
                  width: 100px;
                  font-weight: 500;
                  font-size: 12px;
                  padding: 4px 8px;
                  background: transparent;
                  border-radius: 6px;
                  &.pending {
                    color: #f3c13e;
                    background: rgba(243, 193, 62, 0.1);
                  }
                  &.approved {
                    color: #45bf43;
                    background: rgba(69, 191, 67, 0.1);
                  }
                  &.rejected {
                    color: #ff4242;
                    background: rgba(255, 66, 66, 0.1);
                  }
                  &.claimed {
                    color: #4284ff;
                    background: rgba(66, 132, 255, 0.1);
                  }
                }
                .img {
                  height: 40px;
                  width: 40px;
                  margin-right: 10px;
                  object-fit: contain;
                  border-radius: 50%;
                }

                .icon {
                  margin: 5px;
                  svg {
                    height: 20px;
                    width: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .comment-sec {
      margin: 20px 0;
      border-radius: 8px;
      padding: 24px 20px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
      .lbl {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #717579;
        margin-bottom: 6px;
      }
      .txt {
        padding: 8px;
        min-height: 100px;
        border: 1px solid #bebebe;
        border-radius: 6px;
        background: transparent;
        @include anim;
        &:focus {
          border-color: $themeColor;
        }
      }
    }
    .actions {
      padding: 30px 0;
      .btn {
        padding: 8px;
        width: 110px;
        color: $themeColor;
        background: none;
        border: 1px solid #4e7a7d;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03));
        border-radius: 6px;
        &:last-child {
          background: $themeColor;
          color: #ffffff;
          // margin-right: 12px;
        }
      }
    }
  }
}
