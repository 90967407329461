.rti--container {
  padding: 8px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #0f0f0f !important;
  border: 1px solid #d7d7d7 !important;
  border-radius: 6px !important;
  width: 100%;
}

.rti--tag {
  margin-right: 5px !important;
  margin-bottom: 5px !important;
  background: #4e7a7d !important;
  color: #fff !important;
  padding: 3px 4px !important;
  border-radius: 4px !important;
}
.rti--input {
  width: 100% !important;
}

.rti--tag button {
  margin: 0 5px !important;
}

/* MultiTagInputs */

.tags-input-container {
  padding: 4px 8px;
  font-weight: 400;
  font-size: 16px;
  color: #0f0f0f;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}

.tag-item {
  background-color: #4e7a7d;
  display: inline-block;
  padding: 2px 10px;
  border-radius: 20px;
  color: #fff;
}
.tag-item .close {
  height: 18px;
  width: 18px;
  background: #0f0f0f;
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 16px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
}
