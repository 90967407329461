.sidebar {
  width: 100%;
  /* width: 260px; */
  flex-shrink: 0;
  background-color: #4e7a7d;
  height: 100%;
  overflow: auto;
  color: #d7d7d7;
}

.sidebar-item {
  padding: 0.75em 1em;
  display: block;
  transition: background-color 0.15s;
  border-radius: 5px;
  color: #d7d7d7;
  /* margin: 0 10px; */
}
.sidebar-item:hover {
  /* background: rgba(255, 255, 255, 0.1); */
  border-radius: 6px;
  cursor: pointer;
}

.my-item:hover {
  color: #b7b6b6;
  background: rgba(255, 255, 255, 0.1);
}

/* .my-item.active1 {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
} */

.sidebar-title {
  display: flex;
  font-size: 1.2em;
  justify-content: space-between;
}
.sidebar-title span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}
.sidebar-title span .icon {
  display: inline-block;
  width: 1.5em;
}
.sidebar-title span .icon svg {
  width: 24px;
  height: 24px;
}
.sidebar-title span .icon svg path {
  fill: #d7d7d7;
}
.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}
.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}
.sidebar-content {
  padding-top: 0.25em;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  margin-left: 16px;
  color: #d7d7d7;
  text-decoration: none;
  margin-bottom: 5px;
}
.sidebar-item.plain.active {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}
.sidebar-item.plain:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  /* text-decoration: underline; */
}
.sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}
